import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";

/* eslint-disable camelcase */

class AuthService extends FuseUtils.EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (err) => {
                return new Promise((resolve, reject) => {
                    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        // if you ever get an unauthorized response, logout the user
                        this.emit("onAPIAutoLogout", "Invalid access_token");
                        this.setSession(null);
                    }
                    throw err;
                });
            }
        );
    };

    handleAuthentication = () => {
        const access_token = this.getAccessToken();

        if (!access_token) {
            this.emit("onAPINoAccessToken");

            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit("onAPIAutoLogin", true);
        } else {
            this.setSession(null);
            this.emit("onAPIAutoLogout", "access_token expired");
        }
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            axios.post("/api/auth/register", data).then((response) => {
                if (response.data.user) {
                    this.setSession(response.data.access_token);
                    resolve(response.data.user);
                } else {
                    reject(response.data.error);
                }
            });
        });
    };

    signInWithEmailAndPassword = (email, password) => {
        return new Promise((resolve, reject) => {
            axios
                .post(process.env.REACT_APP_API_URL + "adminLogin", {
                    email,
                    password,
                })
                .then((response) => {
                    if (response.data.user) {
                        this.setSession(response.data.access_token);
                        localStorage.setItem("userData", JSON.stringify(response.data.user));

                        resolve(response.data.user);
                    } else {
                        reject(response.data.error);
                    }
                });
        });
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(process.env.REACT_APP_API_URL + "getAdminProfile")
                .then((response) => {
                    console.log(response);
                    if (response.data.user) {
                        this.setSession(response.data.access_token, response.data.user);
                        resolve(response.data.user);
                    } else {
                        this.logout();
                        reject(new Error("Failed to login with token."));
                    }
                })
                .catch((error) => {
                    this.logout();
                    reject(new Error("Failed to login with token."));
                });
        });
    };

    updateUserData = (user) => {
        return axios.post("/api/auth/user/update", {
            user,
        });
    };

    setSession = (access_token) => {
        if (access_token) {
            localStorage.setItem("safeer_admin_access_token", access_token);
            axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        } else {
            localStorage.removeItem("safeer_admin_access_token");
            delete axios.defaults.headers.common.Authorization;
        }
    };

    logout = () => {
        this.setSession(null);
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
            return false;
        }
        /* const decoded = jwtDecode(access_token);
     const currentTime = Date.now() / 1000;
     if (decoded.exp < currentTime) {
       console.warn('access token expired');
       return false;
     }*/

        return true;
    };

    getAccessToken = () => {
        return window.localStorage.getItem("safeer_admin_access_token");
    };
}

const instance = new AuthService();

export default instance;
