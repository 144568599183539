import { lazy } from "react";

const DeliveryCompaniesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/deliveryCompanies",
            component: lazy(() => import("./DeliveryCompanies")),
        },
        {
            path: "/setup/addDeliveryCompanie",
            component: lazy(() => import("./AddDeliveryCompanie")),
        },
        {
            path: "/setup/editDeliveryCompanie/:id",
            component: lazy(() => import("./EditDeliveryCompanie")),
        },
    ],
};

export default DeliveryCompaniesPageConfig;
