import { createSelector, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { navigationConfig, storeNavigation } from "app/fuse-configs/navigationConfig";
import FuseUtils from "@fuse/utils";
import i18next from "i18next";
import _ from "@lodash";
import { useSelector } from "react-redux";

const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();

export const convertNavigationConfig = (user) => (dispatch, getState) => {
    //console.log(user, "lll");

    if (!getState().auth.user.isAdmin) {
        return dispatch(setNavigation(FuseUtils.changeNavItem(storeNavigation)));
    } else {
        return dispatch(setNavigation(FuseUtils.changeNavItem(navigationConfig)));
    }
};

const initialState = navigationAdapter.upsertMany(emptyInitialState, navigationConfig);

export const appendNavigationItem = (item, parentId) => (dispatch, getState) => {
    const navigation = selectNavigationAll(getState());

    return dispatch(setNavigation(FuseUtils.appendNavItem(navigation, item, parentId)));
};

export const prependNavigationItem = (item, parentId) => (dispatch, getState) => {
    const navigation = selectNavigationAll(getState());

    return dispatch(setNavigation(FuseUtils.prependNavItem(navigation, item, parentId)));
};

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
    const navigation = selectNavigationAll(getState());

    return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
    const navigation = selectNavigationAll(getState());

    return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const { selectAll: selectNavigationAll, selectIds: selectNavigationIds, selectById: selectNavigationItemById } = navigationAdapter.getSelectors((state) => state.fuse.navigation);

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setNavigation: navigationAdapter.setAll,
        changeNavigation: (state, { payload }) => {
           //console.log(state, "state");
            //console.log(payload, "ooooo");
            state = payload;
        },
        resetNavigation: (state, action) => initialState,
    },
});

export const { setNavigation, resetNavigation, changeNavigation } = navigationSlice.actions;

const getUserRole = (state) => state.auth.user.role;

export const selectNavigation = createSelector([selectNavigationAll, ({ i18n }) => i18n.language, getUserRole], (navigation, language, userRole) => {
    function setTranslationValues(data) {
        // loop through every object in the array
        return data.map((item) => {
            if (item.translate && item.title) {
                item.title = i18next.t(`navigation:${item.translate}`);
            }

            // see if there is a children node
            if (item.children) {
                // run this function recursively on the children array
                item.children = setTranslationValues(item.children);
            }
            return item;
        });
    }

    return setTranslationValues(
        _.merge(
            [],
            FuseUtils.filterRecursive(navigation, (item) => FuseUtils.hasPermission(item.auth, userRole))
        )
    );
});

export const selectFlatNavigation = createSelector([selectNavigation], (navigation) => FuseUtils.getFlatNavigation(navigation));

export default navigationSlice.reducer;
