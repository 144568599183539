import { lazy } from "react";

const DeliveryCompanyPlacesPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/deliveryCompanyPlaces/:delivery_company_id?",
            component: lazy(() => import("./DeliveryCompanyPlaces")),
        },
        {
            path: "/setup/addDeliveryCompanyPlace/:delivery_company_id?",
            component: lazy(() => import("./AddDeliveryCompanyPlace")),
        },
        {
            path: "/setup/editDeliveryCompanyPlace/:delivery_company_id?/:id",
            component: lazy(() => import("./EditDeliveryCompanyPlace")),
        },
    ],
};

export default DeliveryCompanyPlacesPageConfig;
