import { authRoles } from "app/auth";
import i18next from "i18next";
import DocumentationNavigation from "../main/documentation/DocumentationNavigation";

import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const adminNavigation = [
    {
        id: "pages",
        title: "Pages",
        type: "group",
        icon: "pages",
        children: [
            {
                id: "dashboards",
                title: "Dashboards",
                translate: "DASHBOARDS",
                type: "item",
                url: "/apps/dashboards/project",
                icon: "dashboard",
            },
            {
                id: "setup",
                title: "Setup",
                translate: "SETUP",
                type: "collapse",
                icon: "engineering",
                children: [
                    {
                        id: "places",
                        title: "Places",
                        type: "item",
                        url: "/setup/places",
                        exact: true,
                    },
                    {
                        id: "languages",
                        title: "Languages",
                        type: "item",
                        url: "/setup/languages",
                        exact: true,
                    },
                    {
                        id: "appIntro",
                        title: "App Intro",
                        type: "item",
                        url: "/setup/appIntro",
                        exact: true,
                    },
                    {
                        id: "brands",
                        title: "Brands",
                        type: "item",
                        url: "/setup/brands",
                        exact: true,
                    },
                    {
                        id: "publicPages",
                        title: "Public Pages",
                        type: "item",
                        url: "/setup/publicPages",
                        exact: true,
                    },
                    {
                        id: "sliders",
                        title: "Sliders",
                        type: "item",
                        url: "/setup/sliders",
                        exact: true,
                    },
                    {
                        id: "helpSections",
                        title: "Help Sections",
                        type: "item",
                        url: "/setup/helpSections",
                        exact: true,
                    },
                    {
                        id: "benefits",
                        title: "Benefits",
                        type: "item",
                        url: "/setup/benefits",
                        exact: true,
                    },
                    {
                        id: "socialMedia",
                        title: "Social Media",
                        type: "item",
                        url: "/setup/SocialMedia",
                        exact: true,
                    },
                    {
                        id: "paymentMethods",
                        title: "Payment Methods",
                        type: "item",
                        url: "/setup/paymentMethods",
                        exact: true,
                    },

                    // {
                    //     id: "Admin",
                    //     title: "Admin",
                    //     type: "item",
                    //     url: "/setup/Admin",
                    //     exact: true,
                    // },
                ],
            },
            {
                id: "products",
                title: "Products",
                // translate: "ECOMMERCE",
                icon: "headset",
                type: "item",
                url: "/setup/products",
            },
            {
                id: "orders",
                title: "Orders",
                // translate: "ECOMMERCE",
                icon: "backup_table",
                type: "item",
                url: "/setup/orders",
            },
            {
                id: "guides",
                title: "Guides",
                type: "item",
                url: "/setup/guides",
                // translate: "ECOMMERCE",
                icon: "style",
            },
            {
                id: "categories",
                title: "Categories",
                type: "item",
                url: "/setup/categories",
                // translate: "ECOMMERCE",
                icon: "category",
            },
            {
                id: "collections",
                title: "Collections",
                type: "item",
                url: "/setup/collections",
                // translate: "ECOMMERCE",
                icon: "collections_bookmark",
            },
            {
                id: "stores",
                title: "Stores",
                type: "item",
                url: "/setup/stores",
                // translate: "ECOMMERCE",
                icon: "storefront",
            },
            {
                id: "users",
                title: "Users",
                type: "item",
                url: "/setup/users",
                icon: "person",
            },
            {
                id: "options",
                title: "Options",
                type: "item",
                url: "/setup/options",
                // translate: "ECOMMERCE",
                icon: "tune",
            },
            {
                id: "campaigns",
                title: "Campaigns",
                type: "item",
                url: "/setup/campaigns",
                // translate: "ECOMMERCE",
                icon: "campaign",
            },
            {
                id: "deliveryCompanies",
                title: "Delivery Companies",
                type: "item",
                url: "/setup/deliveryCompanies",
                // translate: "ECOMMERCE",
                icon: "local_shipping",
            },
            {
                id: "coupons",
                title: "Coupons",
                type: "item",
                url: "/setup/coupons",
                // translate: "ECOMMERCE",
                icon: "card_giftcard",
            },
            {
                id: "taxes",
                title: "Taxes",
                type: "item",
                url: "/setup/taxes",
                // translate: "ECOMMERCE",
                icon: "request_quote",
            },
            {
                id: "commissions",
                title: "Commissions",
                type: "item",
                url: "/setup/commissions",
                // translate: "ECOMMERCE",
                icon: "donut_large",
            },
            {
                id: "labels",
                title: "Labels",
                type: "item",
                url: "/setup/labels",
                // translate: "ECOMMERCE",
                icon: "local_offer",
            },
            {
                id: "ads",
                title: "Ads",
                type: "item",
                url: "/setup/ads",
                // translate: "ECOMMERCE",
                icon: "panorama",
            },
            // {
            //     id: "ads",
            //     title: "Ads",
            //     // translate: "SETUP",
            //     type: "collapse",
            //     icon: "burst_mode",
            //     children: [
            //         {
            //             id: "adsWeb",
            //             title: "Ads Web",
            //             type: "item",
            //             url: "/setup/ads",
            //             // translate: "ECOMMERCE",
            //             icon: "panorama",
            //         },
            //         {
            //             id: "adsMobile",
            //             title: "Ads Mobile",
            //             type: "item",
            //             url: "/setup/",
            //             // translate: "ECOMMERCE",
            //             icon: "book_online",
            //         },
            //     ],
            // },

            {
                id: "settings",
                title: "Settings",
                type: "item",
                url: "/setup/settings",
                // translate: "ECOMMERCE",
                icon: "settings",
            },
        ],
    },
];
const storeNavigation = [
    {
        id: "pages",
        title: "Pages",
        type: "group",
        icon: "pages",
        children: [
            {
                id: "dashboards",
                title: "Dashboards",
                translate: "DASHBOARDS",
                type: "item",
                url: "/apps/dashboards/project",
                icon: "dashboard",
            },
            {
                id: "products",
                title: "Products",
                // translate: "ECOMMERCE",
                icon: "headset",
                type: "item",
                url: "/setup/products",
            },
            {
                id: "orders",
                title: "Orders",
                // translate: "ECOMMERCE",
                icon: "backup_table",
                type: "item",
                url: "/setup/orders",
            },
            {
                id: "stores",
                title: "Store Settlements",
                type: "item",
                url: "/setup/storeSettlements",
                // translate: "ECOMMERCE",
                icon: "storefront",
            },
            {
                id: "store_Statement",
                title: "Store Statement",
                type: "item",
                url: "/setup/storeStatement",
                // translate: "ECOMMERCE",
                icon: "storefront",
            },
            {
                id: "Store_Users",
                title: "Store Users",
                type: "item",
                url: "/setup/storeUsers/4",
                icon: "person",
            },
        ],
    },
];
const navigationConfig = adminNavigation;

export { navigationConfig, storeNavigation };
