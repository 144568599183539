import { lazy } from "react";

const AdminPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/Admin/:type?",
            component: lazy(() => import("./Admin")),
        },
        {
            path: "/setup/addAdmin/:type?",
            component: lazy(() => import("./AddAdmin")),
        },
        {
            path: "/setup/editAdmin/:type?/:id",
            component: lazy(() => import("./EditAdmin")),
        },
    ],
};

export default AdminPageConfig;
