import { lazy } from "react";

const DeliveryCompaniesStatementPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/deliveryCompaniesStatement/:delivery_company_id?",
            component: lazy(() => import("./DeliveryCompaniesStatement")),
        },
    ],
};
export default DeliveryCompaniesStatementPageConfig;
