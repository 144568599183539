import PlacesPageConfig from "./setup/places/PlacesPageConfig";
import LanguagesPageConfig from "./setup/languages/LanguagesPageConfig";
import AppIntroPageConfig from "./setup/appIntro/AppIntroPageConfig";
import BrandsPageConfig from "./setup/brands/BrandsPageConfig";
import PublicPagesPageConfig from "./setup/publicPage/PublicPagesPageConfig";
import SlidersPageConfig from "./setup/sliders/SlidersPageConfig";
import HelpSectionsConfig from "./setup/helpSections/HelpSectionsConfig";
import BenefitsPageConfig from "./setup/benefits/BenefitsPageConfig";
import SocialMediaPageConfig from "./setup/socialMedia/SocialMediaPageConfig";
import PaymentMethodsPageConfig from "./setup/paymentMethods/PaymentMethodsPageConfig";
import GuidesPageConfig from "./setup/guides/GuidesPageConfig";
import CategoriesPageConfig from "./setup/categories/CategoriesPageConfig";
import StoresPageConfig from "./setup/stores/StoresPageConfig";
import ProductsPageConfig from "./setup/products/ProductsPageConfig";
import OptionsPageConfig from "./setup/options/OptionsPageConfig";
import OptionValuesPageConfig from "./setup/optionValues/OptionValuesPageConfig";
import ProductOptionsPageConfig from "./setup/productOptions/ProductOptionsPageConfig";
import UsersPageConfig from "./setup/users/UsersPageConfig";
import AdminPageConfig from "./setup/admin/AdminConfig";
import OrdersPageConfig from "./setup/orders/OrdersPageConfig";
import CampaignsPageConfig from "./setup/campaigns/CampaignsPageConfig";
import DeliveryCompanyPlacesPageConfig from "./setup/deliveryCompanies/deliveryCompanyPlaces/DeliveryCompanyPlacesPageConfig";
import DeliveryCompaniesStatementPageConfig from "./setup/deliveryCompanies/deliveryCompaniesStatement/DeliveryCompaniesStatementPageConfig";
import DeliveryCompaniesSettlementPageConfig from "./setup/deliveryCompanies/deliveryCompaniesSettlements/DeliveryCompaniesSettlementPageConfig";
import ProductImagesPageConfig from "./setup/products/productImages/ProductImagesPageConfig";
import DeliveryCompaniesPageConfig from "./setup/deliveryCompanies/DeliveryCompaniesPageConfig";
import ProductSpecificationPageConfig from "./setup/products/productSpecifications/ProductSpecificationPageConfig";
import ProductReviewsPageConfig from "./setup/products/productReviews/ProductReviewsPageConfig";
import ProductRelatedPageConfig from "./setup/products/productRelated/ProductRelatedPageConfig";
import CouponsPageConfig from "./setup/coupons/CouponsPageConfig";
import TaxesPageConfig from "./setup/taxes/TaxesPageConfig";
import CommissionsPageConfig from "./setup/commissions/CommissionsPageConfig";
import LabelsPageConfig from "./setup/labels/LabelsPageConfig";
import StorePlacesPageConfig from "./setup/stores/storePlaces/StorePlacesPageConfig";
import StoreUsersPageConfig from "./setup/stores/storeUsers/StoreUsersPageConfig";
import StoreStatementPageConfig from "./setup/stores/storeStatement/StoreStatementPageConfig";
import StoreSettlementsPageConfig from "./setup/stores/storeSettlements/StoreSettlementsPageConfig";
import CouponRestrictionsPageConfig from "./setup/coupons/couponRestrictions/CouponRestrictionsPageConfig";
import SettingsPageConfig from "./setup/settings/SettingsPageConfig";
import AdsPageConfig from "./setup/ads/AdsPageConfig";
import CollectionsPageConfig from "./setup/collections/CollectionsPageConfig";

const screensConfigs = [
    PlacesPageConfig,
    CollectionsPageConfig,
    StoreUsersPageConfig,
    SettingsPageConfig,
    DeliveryCompaniesStatementPageConfig,
    DeliveryCompaniesSettlementPageConfig,
    StoreStatementPageConfig,
    StoreSettlementsPageConfig,
    CommissionsPageConfig,
    LabelsPageConfig,
    StorePlacesPageConfig,
    TaxesPageConfig,
    LanguagesPageConfig,
    OrdersPageConfig,
    ProductReviewsPageConfig,
    CampaignsPageConfig,
    CouponRestrictionsPageConfig,
    AppIntroPageConfig,
    ProductImagesPageConfig,
    DeliveryCompaniesPageConfig,
    DeliveryCompanyPlacesPageConfig,
    AdminPageConfig,
    ProductSpecificationPageConfig,
    BrandsPageConfig,
    PublicPagesPageConfig,
    UsersPageConfig,
    ProductRelatedPageConfig,
    SlidersPageConfig,
    HelpSectionsConfig,
    ProductOptionsPageConfig,
    OptionValuesPageConfig,
    OptionsPageConfig,
    BenefitsPageConfig,
    SocialMediaPageConfig,
    PaymentMethodsPageConfig,
    GuidesPageConfig,
    CategoriesPageConfig,
    StoresPageConfig,
    ProductsPageConfig,
    AdsPageConfig,
    CouponsPageConfig,
];

export default screensConfigs;
