import { lazy } from "react";

const DeliveryCompaniesSettlementPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/deliveryCompaniesSettlement/:delivery_company_id?",
            component: lazy(() => import("./DeliveryCompaniesSettlements")),
        },
        {
            path: "/setup/addDeliveryCompaniesSettlement/:delivery_company_id?",
            component: lazy(() => import("./AddDeliveryCompaniesSettlement")),
        },
    ],
};

export default DeliveryCompaniesSettlementPageConfig;
