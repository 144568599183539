import { lazy } from "react";

const OrdersPageConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: "/setup/orders/:from_date?/:to_date?/:status?/:store_id?/:category_id?",
            component: lazy(() => import("./Orders")),
        },
        {
            path: "/setup/addOrder",
            component: lazy(() => import("./AddOrder")),
        },
        {
            path: "/setup/editOrder/:id",
            component: lazy(() => import("./EditOrder")),
        },
        {
            path: "/setup/orderDetails/:id",
            component: lazy(() => import("./order/OrderDetails")),
        },
    ],
};

export default OrdersPageConfig;
